@media (max-width: 460px) {
  .layout-wrapper .layout-main {
    @include transition(margin-left $transitionDuration);
    padding: 91px 16px 16px 16px;
  }
}

.layout-main {
  @include transition(margin-left $transitionDuration);
  padding: 66px 16px 16px 16px;
}
