.p-multiselect .p-multiselect-label-container {
  overflow: hidden;
  width: 190px;
}

.read-more-button {
  display: inline-block;
  margin-left: 3px;
  color: #286da8;
  cursor: pointer;
}

.button-left {
  width: 5em;
  float: left;
  padding-left: 150px;
}

.button-right {
  width: 5em;
  float: right;
  padding-right: 350px;
}

.button {
  background-color: #00a3b4;
  border: 0px;
  width: 160px;
  height: 25px;
  color: white;
}

.init_upload {
  padding-top: 50px;
  padding-bottom: 50px;
}

.push_button {
  padding-left: 550px;
  padding-top: 150px;
  padding-bottom: 5px;
}

.push_space {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.content-wrapper-react {
  margin-top: 40px;
}

.draggableContainer,
.droppableContainer,
.droppableContainerErr {
  border: 1px solid black;
  min-height: 300px;
  border-radius: 7px;
  background: #fff;
  height: 300px;
  overflow-y: scroll;
  padding-left: 15px;
  padding-right: 15px;
}

.droppableContainerErr {
  border: 1px solid red;
}

.p-inputtext {
  width: auto;
}

.row {
  padding-bottom: 10px;
}

.dataSetInput {
  margin-top: 10px;
  width: 140px;
}

.dataSetExpression {
  margin-left: 5px;
}

.p-multiselect-label {
  max-height: 30px;
}

.content-main-react {
  position: relative;
  bottom: 20px;
}

.p-growl.p-growl-topright {
  margin-top: 95px;
}

.droppableContainerErr {
  border: 1px solid red;
}

.react-page-heading {
  margin-left: -50px;
  margin-bottom: 10px;
}

.p-label {
  font-weight: normal;
  color: grey;
  font-size: 1em;
}

.toggleOptions {
  cursor: pointer;
}

.reactSpaceRight {
  margin-right: 10px;
}

.chip {
  font-size: 70%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  margin-left: 3px;
}

.chipHeading {
  margin-left: 10px;
}

.hiddenLabel {
  visibility: hidden;
}

.tab-box {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: 100%;
}
.boxHeading {
  width: 100%;
}

.boxHeadingAction {
  float: right;
}

.boxHeadingText {
  width: 400px;
}

.input-switch {
  width: 38px;
  margin-left: 10px;
  top: 7px;
  height: 25px;
  position: relative;
}

.child-menu {
  margin-left: 20px;
}

.loading-spinner {
  margin-left: 40%;
  margin-top: 20%;
}
.action-button {
  margin-top: 30px;
}

.chart-box {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: transparent !important;
  border-top-color: #00d2ee !important;
  border-top-width: 5px !important;
  border-top-left-radius: 9px !important;
  border-top-right-radius: 9px !important;
}
.chart-box2 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: transparent !important;
  /* border-top-color: #00d2ee !important; */
  /* border-top-width: 5px !important; */
  border-top-left-radius: 9px !important;
  border-top-right-radius: 9px !important;
}

.orangep .p-datatable-tbody > tr > td {
  color: rgb(0, 0, 0) !important;
  padding: 0% !important;
}
.orangep .p-datatable-tbody > tr > td {
  background-color: white !important;
}
.orange .p-datatable-tbody > tr > td {
  /* :nth-child(even) */
  background-color: #ffffff !important;
  padding: 0% !important;
}
.orange.toppoten .p-datatable-tbody > tr > td {
  /* :nth-child(even) */
  background-color: #ffffff !important;
  padding: 0.5% !important;
}
.engtab .p-datatable-tbody,
.pvspt .p-datatable-tbody > tr > td {
  /* :nth-child(even) */
  background-color: #ffffff !important;
}
// .engtab .p-datatable-tbody > tr:nth-child(1),
// .engtab .p-datatable-tbody > tr:nth-child(1) .clickbtn,
// .engtab .p-datatable-tbody > tr:nth-child(2),
// .engtab .p-datatable-tbody > tr:nth-child(2) .clickbtn,
// .engtab .p-datatable-tbody > tr:nth-child(3),
// .engtab .p-datatable-tbody > tr:nth-child(3) .clickbtn {
//   /* :nth-child(even) */
//   color: #ff0000 !important;
// }

// .enggtab .p-datatable-tbody > tr:nth-child(1),
// .enggtab .p-datatable-tbody > tr:nth-child(1) .clickbtn,
// .enggtab .p-datatable-tbody > tr:nth-child(2),
// .enggtab .p-datatable-tbody > tr:nth-child(2) .clickbtn,
// .enggtab .p-datatable-tbody > tr:nth-child(3),
// .enggtab .p-datatable-tbody > tr:nth-child(3) .clickbtn {
//   /* :nth-child(even) */
//   color: #1edd47 !important;
// }

.orange {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  border-top-left-radius: 9px !important;
  border-top-right-radius: 9px !important;
}

.orangep .p-datatable-thead > tr > th {
  background-color: #ffffff !important;
  color: #34495e !important;
  padding: 0.5% !important;
}
.orange .p-datatable-thead > tr > th {
  background-color: #ffffff !important;
  color: #34495e !important;
  padding: 0.5% !important;
}

.orange .p-datatable-header {
  background-color: transparent !important;
  border-top-color: $cardborder !important;
  border-top-width: 5px !important;
  border-top-left-radius: 9px !important;
  border-top-right-radius: 9px !important;
  color: #34495e !important;
}

.box-topborder {
  border-top-color: #00d2ee !important;
  border-top-width: 5px !important;
  border-top-left-radius: 9px !important;
  border-top-right-radius: 9px !important;
}

.orange .p-sortable-column.p-highlight,
.oranget .p-sortable-column.p-highlight {
  color: black !important;
}

.orange .p-sortable-column.p-highlight .p-sortable-column-icon,
.oranget .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: black !important;
}

.p-highlight-yellow {
  background-color: rgb(250, 250, 121) !important;
  color: black !important;
}

.p-highlight-red {
  background-color: rgb(248, 218, 218) !important;
  color: black !important;
}

.p-highlight-white {
  background-color: rgb(255, 255, 255) !important;
  color: black !important;
}

.p-highlight-green {
  background-color: #c5f1ca !important;
  color: black !important;
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight a {
  background-color: #00a3b4 !important;
  border: #00a3b4 !important;
}

.oranget .p-datatable-thead > tr > th {
  /* background-color: #ffd700 !important; */
  background-color: #ffffff !important;
  color: #34495e !important;
}

.oranget .p-datatable-header {
  background-color: #ffffff !important;
  border-top-color: $cardborder !important;
  border-top-width: 5px !important;
  border-top-left-radius: 9px !important;
  border-top-right-radius: 9px !important;
  color: #34495e !important;
}

.oranget-engIndex .p-datatable-header {
  background-color: #ffffff !important;
  color: #34495e !important;
}

body .competency-table-select .p-datatable .p-datatable-tbody {
  cursor: pointer !important;
}

.background-cell-grey {
  background: #d9d9d9 !important;
}

.background-cell-green {
  background: #e1ffd1 !important;
}

.background-cell-red {
  background: #ffad99 !important;
}

.table-color-legend {
  margin: 0px 9px;
  padding: 5px 9px;
  border: 2px solid #808080;
}

.text-black {
  color: black;
}

.text-red {
  color: red;
}

.text-value-green {
  color: #008290;
}

.tabledis {
  display: table !important;
}

.tablecelldis {
  display: table-cell !important;
}

.potentail-value-span {
  border: 3px solid #008290;
  border-radius: 50px;
  width: 50px;
  height: 50px;
  position: absolute;
  text-align: center;
  z-index: 9;
  left: 12%;
  top: 21%;
}
.potentail-value-span-team {
  border: 3px solid #008290;
  border-radius: 50px;
  width: 50px;
  height: 50px;
  position: absolute;
  text-align: center;
  z-index: 9;
  left: 12%;
  top: 5%;
}

.potentail-title {
  position: absolute;
  text-align: center;
  z-index: 9;
  left: 8.5%;
  top: 15%;
  font-size: 100%;
  font-style: bold;
  font-weight: 1000;
}
.low-value-span {
  position: absolute;
  text-align: center;
  z-index: 9;
  left: 10.5%;
  top: 79%;
  font-size: 75%;
}

.high1-value-span {
  position: absolute;
  text-align: center;
  z-index: 9;
  left: 84%;
  top: 82%;
  font-size: 75%;
}

.high2-value-span {
  position: absolute;
  text-align: center;
  z-index: 9;
  left: 9.5%;
  top: 18.5%;
  font-size: 75%;
}

.yaxis-value-span {
  position: absolute;
  text-align: center;
  z-index: 9;
  left: 35%;
  top: 88%;
  font-size: 100%;
  color: red;
}

.potentail-value {
  position: relative;
  top: 23%;
  right: 2%;
}
.potentail-value-team {
  position: relative;
  top: 23%;
  right: 2%;
}

.metable {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  border-top-left-radius: 9px !important;
  border-top-right-radius: 9px !important;
}

.metable .p-datatable-thead > tr > th {
  background-color: #ffffff !important;
  color: #34495e !important;
  /* padding: 0.5% !important; */
}

.metable .p-datatable-thead > tr {
  background-color: #ffffff !important;
  color: #34495e !important;
  /* padding: 0.5% !important; */
}

.metable .p-datatable-header {
  background-color: rgba(255, 255, 255, 0) !important;
  border-top-color: #00d2ee00 !important;
  border-top-width: 5px !important;
  border-top-left-radius: 9px !important;
  border-top-right-radius: 9px !important;
  color: #34495e !important;
}

.flex-container,
.chart-container-dim-inf {
  display: flex !important;
}

.flex {
  flex: 1 !important;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.total-employees-container {
  padding: 5px;
  float: right !important;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight.high-score-background a {
  color: #383c42 !important;
  background: #fff !important;
  border-bottom: 3px solid #9ccc65 !important;
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight.low-score-background a {
  background: #fff !important;
  color: #383c42 !important;
  border-bottom: 3px solid #ff8a65 !important;
}

body .p-tabview .p-tabview-panels {
  border: none !important;
}

body .p-tabview.p-tabview-top .p-tabview-nav li a,
body .p-tabview.p-tabview-bottom .p-tabview-nav li a,
body .p-tabview.p-tabview-left .p-tabview-nav li a,
body .p-tabview.p-tabview-right .p-tabview-nav li a {
  background: white !important;
}

body .p-tabview.p-tabview-top .p-tabview-nav li a:not(.p-disabled):focus,
body .p-tabview.p-tabview-bottom .p-tabview-nav li a:not(.p-disabled):focus,
body .p-tabview.p-tabview-left .p-tabview-nav li a:not(.p-disabled):focus,
body .p-tabview.p-tabview-right .p-tabview-nav li a:not(.p-disabled):focus {
  box-shadow: none !important;
}

body .p-tabview.p-tabview-top .p-tabview-nav li a,
body .p-tabview.p-tabview-bottom .p-tabview-nav li a,
body .p-tabview.p-tabview-left .p-tabview-nav li a,
body .p-tabview.p-tabview-right .p-tabview-nav li a {
  border: none !important;
}
body .highProficiency .box-topborder,
body .highProficiency .p-datatable .p-datatable-header {
  border-top-color: #cefad7 !important;
}

body .inadequacy .box-topborder,
body .inadequacy .p-datatable .p-datatable-header {
  border-top-color: #ffdbdc !important;
}

body .proficiency .box-topborder,
body .proficiency .p-datatable .p-datatable-header {
  border-top-color: #feefc8 !important;
}

body .moderatepotential .box-topborder,
body .moderatepotential .p-datatable .p-datatable-header {
  border-top-color: #ffacac70 !important;
}

body .goodpotential .box-topborder,
body .goodpotential .p-datatable .p-datatable-header {
  border-top-color: #fbbc0540 !important;
}

body .highpotential .box-topborder,
body .highpotential .p-datatable .p-datatable-header {
  border-top-color: #8ff4a370 !important;
}

.medialog .p-dialog-titlebar {
  border: 1px solid rgba(255, 255, 255, 0) !important;
  background-color: rgb(255, 255, 255) !important;
  border-top-color: #00d2ee !important;
  border-top-width: 5px !important;
  border-top-left-radius: 9px !important;
  border-top-right-radius: 9px !important;
}
.doughnut > .col-md-6 {
  padding-right: 9px !important;
}

.custom-tab-container {
  text-align: center;
}

.chart-container-dim-inf {
  padding: 10px !important;
  margin: 10px !important;
}

.diminf-12-container {
  padding-left: 35px !important;
  padding-right: 35px !important;
  padding-top: 10px !important;
}

.tabName {
  color: #383c42 !important;
  background: #fff !important;
  font-weight: bold;
  padding: 5px;
  cursor: pointer;
}

.tabName1 {
  background: #fff !important;
  font-weight: bold;
  padding: 5px;
  cursor: pointer;
}

.tab-name-high-pot {
  border-bottom: 3px solid #a1d97c !important;
}

.tab-name-mid-pot {
  border-bottom: 3px solid #fdc667 !important;
}

.tab-name-low-pot {
  border-bottom: 3px solid #fe876f !important;
}

.tab-name-high-proficiency {
  border-bottom: 3px solid #9ccc65 !important;
}

.tab-name-mid-proficiency {
  border-bottom: 3px solid #00d2ee !important;
}

.tab-name-low-proficiency {
  border-bottom: 3px solid #ff8a65 !important;
}

.tab-name-unclicked {
  border-bottom: 3px solid grey !important;
}

.tab-name-moderate {
  border-bottom: 3px solid #ffcb07 !important;
}

.tab-name-concern {
  border-bottom: 3px solid #fe624c !important;
}

.tab-name-good {
  border-bottom: 3px solid #00e781 !important;
}

.rowgap {
  padding-bottom: 0;
}
.boxmar {
  margin-bottom: 0 !important;
}

.opinionemoji:hover {
  color: green !important;
}
.opinionemoji2:hover {
  color: blue !important;
}
.opinionemoji3:hover {
  color: red !important;
}

.opinionemojistyle {
  font-size: 40px;
  cursor: pointer;
  text-align: center;
  color: green;
}
.opinionemojistyle2 {
  font-size: 40px;
  cursor: pointer;
  text-align: center;
  color: blue;
}
.opinionemojistyle3 {
  font-size: 40px;
  cursor: pointer;
  text-align: center;
  color: red;
}
.opinionemojistyle4 {
  font-size: 30px;
  cursor: pointer;
  text-align: center;
  color: #80808080;
}
.error-text-message {
  font-weight: bold;
  color: red;
  text-align: center;
}

.top-padding-20 {
  padding-top: 20px;
}
.topbar-text-header {
  font-size: 12px;
  color: black;
  padding: 1em;
  color: white;
  a {
    color: white;
  }
}

div.layout-sidebar:hover {
  overflow: auto;
  padding-right: 1px;
}

.modal-title.modal-description {
  margin-left: 2em;
}

.layout-main-public {
  padding: 20px;
  margin-top: 10%;
}

.auto-center-container-login {
  max-width: 400px;
  margin: auto;
  transition: 0.3s;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.login-nav-header {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.login-header-message {
  font-weight: 600;
  font-size: 30px;
  text-align: center;
  img {
    margin-top: 20px;
  }
}

.login-subheader-message {
  font-size: 16px;
  font-weight: 400;
  padding: 5px;
  text-align: center;
}

.img-responsive {
  padding: 10px;
}

.img-question {
  padding: 10px;
  image-rendering: auto;
  border: 10px;
  border-radius: 20px;
  width: 100%;
  height: 100%;}

.topbar-login-text {
  margin-top: 20px;
  padding: 5px;
  position: absolute;
  right: 2px;
  color: #999;
}

.login-nav-icon {
  position: relative;
  top: 4px;
}

.login-form-center-align {
  padding: 20px;
  margin: auto;
}

.login-form-center-align div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form-center-align .p-inputgroup {
  padding-bottom: 10px;
}

.login-form-center-align .input-text {
  width: 250px;
}

.login-button,
body .login-button.p-button.p-button-icon-only .submit-button {
  width: 285px;
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}

.progress-container {
  position: relative;
}

.progress-custom-bar {
  position: relative;
}

body .desktop-progressbar {
  height: 0.5vh;
}

.response-container {
  padding-top: 10px;
  padding-bottom: 30px;
}

.star-svg {
  width: 36px;
  height: 36px;
}

.round-button .pi {
  font-size: 3em;
}

.p-button.p-component.round-button {
  width: 4.5em;
}

.dialog-action-button {
  padding-right: 1em;
  text-align: right;
  padding-top: 1em;
}
.dialog-action-button-reset {
  padding-left: 1em;
  text-align: left;
  padding-top: 1em;
}

.rating-value {
  text-align: center;
  padding: 0.5em;
}

.question-container {
  text-align: center;
  margin-top: 1em;
}

.dialog-p-container {
  border-bottom: 1px solid $button;
  padding-top: 0.2em;
}

.dialog-p-container-no-bottom {
  padding-top: 1em;
}

@media (min-width: 1000px) {
  .p-dialog.p-component.language-container {
    width: 25%;
  }
}

@media (max-width: 1000px) {
  .p-dialog.p-component.language-container {
    width: 50%;
  }
}

@media (max-width: 500px) {
  .p-dialog.p-component.language-container {
    width: 80%;
  }
}

.dialog-footer-container {
  padding-top: 1.5em;
  margin-right: 0.5em;
  text-align: right;
}

html,
body {
  height: 100%;
}

body #root .layout-main.powered-by-yeta {
  text-align: center;
  min-height: 1vh;
  padding: 0px;
}

.forgot-password-message {
  padding: 2em;
  color: $forgotpasswordmsg;
}

.clickable-link {
  cursor: pointer;
  color: $clickablelink;
}

.error-text {
  color: red;
  font-size: 0.8em;
  padding: 0.5em;
}

.rating-count-container,
.radio-button-rating-container {
  padding: 0.5em;
}

html {
  scroll-behavior: smooth;
}

.dnd-icon {
  left: -10px;
  position: relative;
}

.ranking-response-container {
  margin-left: -40px;
}

.container-upload {
  margin-top: 10px;
}

.user-upload {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.noPaddingPanel .p-panel-titlebar {
  padding: 0 !important;
}

.center-align {
  text-align: center;
  width: 20em;
}

.left-pad {
  margin-left: 5px;
}

.font-awesome-icons-topbar {
  font-size: 1.6em;
  position: relative;
  bottom: 0.17em;
}
.logout-icon {
  color: #ffba01;
}

.layout-topbar .layout-topbar-icons button span.layout-topbar-icon {
  font-size: 2.5em;
}

.layout-topbar .layout-topbar-icons button,
.layout-topbar .layout-topbar-icons .icon-container-topbar {
  margin-left: 1em !important;
}

body .layout-topbar-icons {
  padding: 10px;
}

body .layout-topbar-icons.admin {
  padding: 0px;
}

body .layout-topbar .topbar-text {
  padding: 0em 0em 0em 1em;
}

.loading-spinner-container {
  text-align: center;
  margin-top: 25vh;
}

.not-found {
  text-align: center;
}

.displayName {
  position: relative;
  top: -0.3em;
}

.display-percentage {
  text-align: center;
  font-size: small;
  font-weight: bold;
}

body .p-progressbar.mobile-progressbar {
  height: 1vh;
  box-shadow: 1px 1px 10px 2px darkgrey;
}

.relative {
  position: relative;
}

.no-padding {
  padding: 0px;
}

.option-icon {
  font-size: 1.5em;
  margin-right: 1em;
}

.option-container {
  background-color: #89f7fe;
  box-shadow: 1px 1px 4px 2px $shadow !important;
  border-radius: 45px;
}

.option-container-selected {
  background-color: #01dfa5;
  box-shadow: 3px 3px 4px 2px $shadow !important;
  border-radius: 45px;
}

.option-label {
  margin-left: 1em;
}

.top-5 {
  top: -5px;
}

.top-10 {
  top: -10px;
}

.top5 {
  top: 5px;
}

button > span > .pi {
  font-size: 2em;
}

.org-name {
  color: black;
  text-shadow: 3px 3px $shadow !important;
  font-size: 20px;
}

.number-cell {
  text-align: right;
}

.global-filter {
  width: 30vh;
}

.text-align-center {
  text-align: center;
}

.organisation-dropdown-adminbar {
  margin-left: 50px;
}

.dialoag-dropdown {
  width: 100%;
}

.center {
  text-align: center;
}
.anschip {
  display: inline-block;
  padding: 0 10px;
  font-size: 16px;
  border-radius: 25px;
  background-color: #00a3b4;
  color: white;
}

.rankchip {
  display: inline-block;
  text-align: center;
  align-items: center;
  // padding: 2px;
  font-size: 16px;
  border-radius: 25px;
  background-color: #00a3b4;
  margin: 0;
  color: white;
}

.que-tile {
  border-radius: 10px;
  box-shadow: 1px 1px 5px 1px $shadow;
  margin: 1em 0;
}

body .chat-container .p-datatable .p-datatable-thead > tr > th {
  padding: 0%;
  border: 0em;
}

.split-line-chat {
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid grey;
}

body .chat-container .p-datatable .p-datatable-tbody > tr > td {
  border: 0em;
  border-bottom: 1px solid grey;
}

.expanded-row-container {
  margin-left: 3em;
}

.pointer {
  cursor: pointer;
}

body .error {
  border: 1px solid red;
  background: grey;
}

.chat-compose-button-container {
  float: right;
  margin-right: 5vh;
}

.icon-container {
  padding-left: 5px;
  padding-right: 5px;
}

body .overflow-scroll {
  overflow: scroll !important;
}

body .vheight-max-30 {
  max-height: 30vh;
}

body .p-highlight-read {
  background: #e6e6e6 !important;
}

body .p-highlight-unread {
  background: white !important;
}

.container-badge {
  position: fixed;
  top: 5px;
  right: 63px;
  padding: 1px 5px;
  border-radius: 50%;
  background-color: blue;
  color: white;
  font-size: 11px;
  z-index: 1;
}

.menu-label-container {
  max-width: 160px;
}

.profile-card-container {
  .img-wrap {
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto;
    width: 68.3%;
  }
  .img-wrap > img {
    border-radius: 50%;
    width: 200px !important;
    height: 200px !important;
  }
  .img-upload:before {
    content: "\f093";
    font-size: 90px;
    position: absolute;
    padding-top: 50px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #63d3a6;
    width: 202px;
    height: 202px;
    border-radius: 50%;
    opacity: 0;
    transition: 0.5s ease;
    background-color: #fff;
    font-family: "Font Awesome 5 Free";
  }
  .img-upload:hover:before {
    opacity: 0.8;
  }

  .profile-data-container > div > div {
    padding: 0% !important;
  }

  .profile-label {
    color: grey;
  }

  .profile-value {
    font-weight: bold;
    overflow-wrap: break-word;
  }

  img {
    background: url("/loading-icon.gif") 50% no-repeat;
  }
}

.height-200 {
  min-height: 200px;
}

.progress-spinner-profile-pic {
  margin-top: 30px;
}

.brand-icon {
  width: 150px;

  img {
    width: 100px;
    position: relative;
    top: -8px;
    margin-left: 10px;
  }
}

.profile-pic-icon {
  width: 35px;
  position: relative;
  top: -8px;
  border-radius: 50%;
  height: 35px;
  left: -5px;
}

body .text-question-bold {
  font-weight: 510 !important;
  font-size: 16px;
}

.complete-questions-message {
  font-weight: 100;
  color: grey;
  font-size: 13px;
  float: right;
  padding: 7px;
}

body .margin-left-10 {
  margin-left: 30px;
}

body .selected-managers-container {
  padding-right: 8px;
}

body .selected-managers-icon {
  padding-left: 3px;
  cursor: pointer;
  color: #fa9898;
}

body .manager-selection-dashboard {
  padding-bottom: 0px;
}

body .tab-button-dim-inf {
  padding-bottom: 10%;
  padding-top: 10%;
}

.selected-dimension-heading {
  font-weight: 800;
  font-size: 18px;
  padding: 10px;
}

.dimensionwise {
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1) !important;
}

.metable-me-dashboard .p-datatable-header {
  background-color: rgba(255, 255, 255, 0) !important;
  border-bottom: 0px !important;
  text-align: left !important;
}

.metable-me-dashboard.emp-train-details .p-datatable-header {
  background-color: #ff9800 !important;
  border-bottom: 0px !important;
  text-align: left !important;
}

.dashboard-clip-container {
  height: 70vh;
}

body .p-dialog.dashboard-dialog .p-dialog-content {
  overflow: scroll !important;
  height: 80vh !important;
  width: 100% !important;
  background-color: white !important;
}

body .p-dialog.dashboard-dialog {
  width: 90% !important;
}

body .p-panel-title {
  font-size: 16px;
}

body .p-component {
  font-size: 15px !important;
}

.funding-details {
  font-size: 12px;
  color: grey;
  margin-left: 10px;
}

body {
  .engagement-index-container,
  .me-dashboard-tables,
  .clk-table-btn {
    .p-button.p-button-text-only .p-button-text,
    .p-button-text-only .p-button-text {
      //body .p-button.p-button-text-only .p-button-text
      border-radius: 10px !important;
      padding: 0 0.3em !important;
      font-size: 13.5px;
    }
    .clickbtn.p-button.p-button-secondary {
      height: 28px !important;
      background-color: #ffffff !important;
      color: #333333 !important;
      border-color: #ffffff !important;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
    }
    .p-datatable-wrapper {
      border-top: 5px solid #ffffff00;
      border-radius: 10px;
      box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.05);
    }
    .team_green {
      .p-datatable-wrapper {
        border-top: 5px solid #31c736;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.05);
      }
    }
    .team_red {
      .p-datatable-wrapper {
        border-top: 5px solid #f34949;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.05);
      }
    }
    .dis-engagement-tables {
      .p-datatable-wrapper {
        border-top: 5px solid #ffbbab;
      }
    }
    .engagement-tables {
      .p-datatable-wrapper {
        border-top: 5px solid $lightGreen;
      }
    }
    .p-datatable-wrapper {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }
    .border-red {
      border-top: 5px solid $lightRed !important;
    }
    .border-green {
      border-top: 5px solid $lightGreen !important;
    }
  }
}

.red-border-dialog {
  border-top: 5px solid $lightRed !important;
}

.green-border-dialog {
  border-top: 5px solid $lightGreen !important;
}

.formlabel:after {
  content: "*";
  color: red;
}

.emp_sel_list {
  background-color: #007ad9;
  padding: 9px;
  margin: 9px;
  color: #ffffff;
  border-radius: 10px;
}

.summary_count {
  font-weight: 700;
  border: 5px solid;
  padding: 9px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  text-align: center;
}
.summary_count.green {
  border-color: #78c678;
}
.summary_count.grey {
  border-color: #9d9d9d;
}
.summary_count.red {
  border-color: #ff7777;
}

.sel-lang {
  border: 3px solid #00a3b4;
  border-radius: 9px;
  padding: 9px;
  box-shadow: 1px 1px 5px 3px #b9e1e5;
}

.anschip {
  display: inline-block;
  padding: 0 10px;
  font-size: 16px;
  border-radius: 25px;
  background-color: #00a3b4;
  color: white;
}

.cvanschip {
  display: inline-block;
  padding: 0 10px;
  font-size: 16px;
  border-radius: 25px;
  width: auto;
  background-color: white;
  border-color: #00a3b4;
  border-style: solid;
}

.dim_dash{
  div{
    margin: 5px
  }
}
