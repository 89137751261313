//Suggested location to add your overrides so that migration would be easy by just updating the SASS folder in the future
body .p-inputgroup .p-inputgroup-addon {
  padding: 0.25em;
}

#remember-me.p-checkbox-label {
  size: 0.5em;
}

body .p-checkbox .p-checkbox-box {
  width: 16px;
  height: 16px;
}
body .p-checkbox .p-checkbox-box .p-checkbox-icon {
  font-size: 14px;
}
.small-size-font {
  font-size: 12px;
}
.header-fa-icons {
  vertical-align: 0.05em;
  font-size: 1.6em;
}

.p-panel.p-component,
body .p-dialog {
  border-top: 5px solid $cardborder;
  border-radius: 10px;
  box-shadow: 5px 5px 15px 5px $shadow;
  margin-top: 1em;
}
.p-panel.p-component.topquartilepanel,
body .p-dialog {
  border-top: 5px solid #2cc0ff;
  border-radius: 10px;
  box-shadow: 5px 5px 15px 5px $shadow;
  margin-top: 1em;
}
.gridpanel.p-component {
  border-top: 5px solid white;
  border-radius: 10px;
  box-shadow: 5px 5px 15px 5px $shadow;
  margin-top: 1em;
}
.topquartilepanel .p-panel.p-component {
  border-top: 5px solid #2cc0ff;
  border-radius: 10px;
  box-shadow: 5px 5px 15px 5px $shadow;
  margin-top: 1em;
}

.highProficiency .p-panel.p-component {
  border-top: 5px solid #cefad7;
  border-radius: 10px;
  box-shadow: 5px 5px 15px 5px $shadow;
  margin-top: 1em;
}

.proficiency .p-panel.p-component {
  border-top: 5px solid #feefc8;
  border-radius: 10px;
  box-shadow: 5px 5px 15px 5px $shadow;
  margin-top: 1em;
}

.inadequacy .p-panel.p-component {
  border-top: 5px solid #ffdbdc;
  border-radius: 10px;
  box-shadow: 5px 5px 15px 5px $shadow;
  margin-top: 1em;
}

.highpotential .p-panel.p-component {
  border-top: 5px solid #a1d97c;
  border-radius: 10px;
  box-shadow: 5px 5px 15px 5px $shadow;
  margin-top: 1em;
  color: "black";
}

.goodpotential .p-panel.p-component {
  border-top: 5px solid #fdc667;
  border-radius: 10px;
  box-shadow: 5px 5px 15px 5px $shadow;
  margin-top: 1em;
}

.moderatepotential .p-panel.p-component {
  border-top: 5px solid #fe876f;
  border-radius: 10px;
  box-shadow: 5px 5px 15px 5px $shadow;
  margin-top: 1em;
}

.highpotential1.p-panel > .p-panel-titlebar {
  color: #89c760;
}

.goodpotential1.p-panel > .p-panel-titlebar {
  color: #f7b645;
}

.moderatepotential1.p-panel > .p-panel-titlebar {
  color: #fe876f;
}

.highProficiency .orange .p-datatable-header {
  background-color: transparent !important;
  border-top-color: #a1d97c !important;
  border-top-width: 5px !important;
  border-top-left-radius: 9px !important;
  border-top-right-radius: 9px !important;
  color: #34495e !important;
}

.proficiency .orange .p-datatable-header {
  background-color: transparent !important;
  border-top-color: #feefc8 !important;
  border-top-width: 5px !important;
  border-top-left-radius: 9px !important;
  border-top-right-radius: 9px !important;
  color: #34495e !important;
}

.inadequacy .orange .p-datatable-header {
  background-color: transparent !important;
  border-top-color: #ffdbdc !important;
  border-top-width: 5px !important;
  border-top-left-radius: 9px !important;
  border-top-right-radius: 9px !important;
  color: #34495e !important;
}

body .p-panel .p-panel-titlebar,
body .p-dialog .p-dialog-titlebar,
body .p-dialog .p-dialog-content,
body .p-panel .p-toggleable-content,
body .p-panel .p-panel-content {
  border: 0px;
  background-color: white;
}
body .p-dialog .p-dialog-titlebar {
  padding-bottom: 10px;
  font-size: 15px;
}
body .p-dialog .p-dialog-content {
  padding-top: 0px;
}

body .p-panel .p-toggleable-content,
body .p-dialog .p-dialog-content {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
body .p-button {
  border-radius: 45px;
  box-shadow: 1px 1px 4px 2px $shadow !important;
}
body .p-button:enabled:hover {
  transform: translateY(-1px);
  box-shadow: 2px 2px 12px 4px $shadow !important;
}
body .p-button:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.02em $shadow;
  -moz-box-shadow: 0 0 0 0.02em $shadow;
  box-shadow: 0 0 0 0.02em $shadow;
}
body .p-button.p-button-secondary:enabled:hover {
  transform: translateY(-1px);
  box-shadow: 2px 2px 12px 4px $shadow !important;
}
body .p-button.p-button-secondary:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.02em $shadow;
  -moz-box-shadow: 0 0 0 0.02em $shadow;
  box-shadow: 0 0 0 0.02em $shadow;
}
body .p-button:enabled:active {
  background-color: $button !important;
  color: $buttontext !important;
  border-color: $button !important;
}
body .p-progressbar {
  border-radius: 15px;
  background-color: $progressbarbg;
}
body .p-progressbar .p-progressbar-value {
  border-radius: 15px;
  background: $progressbar;
}
body .p-progressbar .p-progressbar-label {
  border-radius: 15px;
  color: #333333;
}

body .p-dialog {
  width: 60%;
}

@media (min-width: 1024px) {
  body .question-container {
    width: 50%;
  }
}

@media (max-width: 1024px) {
  body .question-container {
    width: 80%;
  }
}

.p-dialog .p-dialog-titlebar-icon span {
  font-size: 2em;
}

.animated-rater .react-rater > * {
  margin-right: 1em;
  margin-left: 0;
}

.p-grid {
  margin-top: 0.5em;
}

.p-growl.p-growl-bottomright {
  bottom: auto;
}

.layout-wrapper.layout-static .layout-main,
.layout-wrapper.layout-static .layout-footer {
  min-height: 95vh;
}

body .p-inputgroup .p-inputgroup-addon {
  height: 35px;
}

body .p-panel-content {
  padding-top: 0px;
  position: relative;
  top: -0.6em;
}

body .p-button.p-button-rounded {
  border-radius: 25px;
  // height: 3em;
}

body .login-button.p-button.p-button-icon-only {
  width: 285px;
  align-items: center;
}

.read-more-button {
  color: #00a3b4 !important;
  font-weight: 1000;
  font-style: italic;
}

body .p-datatable-emptymessage {
  text-align: center;
}

body .p-autocomplete.p-component,
body .p-autocomplete.p-component > input {
  width: 90%;
}

body .p-autocomplete.p-component > button {
  width: 10%;
  height: 35px;
  max-width: 35px;
}

body .p-autocomplete.p-component .p-autocomplete-panel {
  width: 90%;
  min-width: 90%;
}

.layout-menu li a span {
  margin-left: 0.5em;
}

body .side-menu-container > a > svg {
  position: relative;
  top: 3px;
}

body .communication-container .p-datatable .p-datatable-header,
body .communication-container .p-paginator {
  border: 0px none;
}

body .gridbox.p-panel .p-panel-content {
  padding: 0;
}

body .profile-overlay.p-overlaypanel.p-component.profile-overlay {
  left: 0px !important;
  right: 0px !important;
  top: 0px !important;
  margin-inline-start: auto;
  margin-right: 30px;
  margin-top: 60px;
  text-align: center;
}

.profile-overlay.p-overlaypanel:after,
.profile-overlay.p-overlaypanel:before {
  content: none;
  position: fixed;
}

body .profile-overlay.p-overlaypanel {
  max-width: 700px;
}

.leaderradarpanel .echarts-for-react div {
  cursor: pointer !important;
}
.readynextdd .p-dropdown-filter-container {
  width: 100%;
}
.cpcl_panel.p-panel.p-component .p-toggleable-content {
  overflow: scroll;
}

body .p-button.p-button-warning.whatbtn {
  background-color: white !important;
  border: 1px solid #00a3b4 !important;
  color: #00a3b4 !important;
  font-weight: 600 !important;
  font-size: 12px !important;
}
body .p-button.p-button-warning.whatbtn:enabled:hover,
body .p-button.p-button-warning.whatbtn:enabled:active {
  background-color: #1bce73 !important;
  border: 1px solid white !important;
  color: white !important;
  font-weight: 600 !important;
  font-size: 12px !important;
}

body .p-button.p-button-warning.purplebtn {
  background-color: #db51f2 !important;
  border: 1px solid #db51f2 !important;
  color: #ffffff !important;
  // font-weight: 600 !important;
  // font-size: 12px !important;
}
body .p-button.p-button-warning.purplebtn:enabled:hover,
body .p-button.p-button-warning.purplebtn:enabled:active {
  background-color: #ffffff !important;
  border: 1px solid #db51f2 !important;
  color: #db51f2 !important;
  // font-weight: 600 !important;
  // font-size: 12px !important;
}
.scrolltable.p-datatable table {
  table-layout: auto !important;
}

body .p-datatable-scrollable-view.p-datatable-unfrozen-view .p-rowgroup-header-name {
  visibility: hidden;
}

body .p-datatable-scrollable-view.p-datatable-frozen-view {
  border-right: 1px solid #ffd966;
}
body .p-datatable.me-datatable .p-datatable-thead > tr > th,
body .p-datatable.me-datatable .p-datatable-tbody > .p-datatable-row > td {
  background-color: white;
}

body .p-datatable-frozen-view > .p-datatable-scrollable-body > table > .p-datatable-tbody > .p-rowgroup-header,
.p-datatable-unfrozen-view > .p-datatable-scrollable-body > table > .p-datatable-tbody > .p-rowgroup-header {
  background-color: #d8d8d8;
}
body .p-datatable.me-datatable .p-datatable-thead > tr > th,
body .p-datatable.me-datatable .p-datatable-tbody > tr > td {
  border-color: #ffd966;
}
body .p-datatable.me-datatable .p-datatable-scrollable-header,
body .p-datatable.me-datatable .p-datatable-scrollable-footer {
  background-color: white;
}
.p-datatable-unfrozen-view > .p-datatable-scrollable-body {
  cursor: all-scroll;
}
.p-datatable.stutable {
  font-size: 12px !important;
}

body .training_table.p-datatable .p-datatable-tbody > .p-highlight {
  background-color: #ff9800 !important;
}
.p-table-reorderablerow-handle {
  cursor: pointer;
}
