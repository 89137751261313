/* General */
$fontSize:14px;
$bodyBgColor:#edf0f5;
$textColor:#333333;
$textSecondaryColor:#707070;
$borderRadius:3px;
$dividerColor:#e3e3e3;
$transitionDuration:.2s;
$maskBgColor:#424242;

/* Menu Common */
$menuitemBadgeBgColor:#00A3B4;
$menuitemBadgeColor:#ffffff;
$submenuFontSize:13px;
$menuitemActiveRouteColor:#00A3B4;

/* Menu Light */
$menuBgColorFirst:#fcfeff;
$menuBgColorLast:#fafeff;
$menuitemColor:#232428;
$menuitemHoverColor:#00A3B4;
$menuitemActiveColor:#00A3B4;
$menuitemActiveBgColor:#ffffff;
$menuitemBorderColor:rgba(207, 211, 224, 0.6);

/* Menu Dark */
$menuDarkBgColorFirst:#4d505b;
$menuDarkBgColorLast:#3b3e47;
$menuitemDarkColor:#ffffff;
$menuitemDarkHoverColor:#00A3B4;
$menuitemDarkActiveColor:#00A3B4;
$menuitemDarkActiveBgColor:#2e3035;
$menuitemDarkBorderColor:rgba(52, 56, 65, 0.6);

/* Topbar */
$topbarLeftBgColor:#00A3B4;
$topbarRightBgColor:#00A3B4;
$topbarItemBadgeBgColor:#ef6262;
$topbarItemBadgeColor:#ffffff;
$topbarItemColor:#ffffff;
$topbarItemHoverColor:#c3e8fb;
$topbarSearchInputBorderBottomColor:#ffffff;
$topbarSearchInputColor:#ffffff;

/* Footer */
$footerBgColor:#ffffff;

$yeta: #00a3b4;
$yetahover: #008c9c;
$cardborder: #39cccc;
$button: #FFC723;
$buttontext: black;
$shadow: rgba(0, 0, 0, 0.2);

/* Progressbar */
$progressbarbg: #eaeaea;
$progressbar: $yeta;

$clickablelink: #101094;
$forgotpasswordmsg: grey;

$lightGreen: #b7edb9;
$lightRed: #ff948f;
$borderRed: #ffbbab;